import './ControleDiario.css'
import React, { useEffect, useState } from 'react'
import SideBar from '../../../components/SideBar/SideBar'
import Header from '../../../components/Header/Header'
import ConfigService from '../../../services/config'
import PostControleDiarioUpdate from '../../../services/PostControleDiarioUpdate'
const { ApiFilter } = require('../../../Functions/FilterApiDomain')

const configBaseObj = {
    id: 0,
    Ano: 0,
    Status: "inativo",
    Nota_Conceito: 0,
    ExcluirDados: 0,
    decimais_nota: 0,
    bloqueio_notas: 0,
    bloqueio_diario: 0,
    bloqueio_diario_dias: 0
}

export default function ControleDiario() {
    const [config, setConfig] = useState(configBaseObj)
    const [bloqueadoValue, setBloqueadoValue] = useState(0)
    const [bloqueadoDias, setBloqueadoDias] = useState(0)
    const sub = localStorage.getItem('target')
    const baseUrl = ApiFilter(sub)

    const PostControleDiario = async () => {
        const result = await PostControleDiarioUpdate(baseUrl, bloqueadoValue, bloqueadoDias)
        return result
    }


    useEffect(() => {
        Promise.all([ConfigService(baseUrl)]).then((response) => {
            setConfig(response[0][0])
        })
    }, [])

    const onChangeBloqueadoDias = (e) => {
        setBloqueadoDias(e.target.value)
    }

    const onChangeBloqueado = (e) => {
        setBloqueadoValue(e.target.value)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        
        const confirm = window.confirm('Deseja realmente salvar as alterações?')
        if (!confirm) return

        const result = await PostControleDiario()
        console.log('result: ', result)

        if (result.status === 200) {    
            window.alert('Atualizado!')
            window.location.reload()
        }

        if (result.status === 500) {
            window.alert('Algo deu errado!')
            window.location.reload()
        }
    }

    return (
        <>
            <div id='HomePageGeneralContainer'>
                <SideBar />
                <div id='HomePageContainer'>
                    <Header />

                    <div id="ControleDiarioTitlePage">
                        Controle Diário
                    </div>

                    <div className="ControleDiarioPainelContainer">

                        <div id="controleDiarioFormContainer">
                            <div id="controleDiarioFormTextContainer">
                                <h2 id="ControleDiarioText">
                                    Gerenciar acesso ao diário
                                </h2>
                                <div id="ControleDiarioSubText">
                                    Gerencie o acesso ao diário de acordo com a quantidade de dias que o professor terá de prazo para registrar a aula.
                                    Caso queira bloquear o diário, marque a opção correspondente.
                                </div>
                            </div>

                            <div id="controleDiarioFormConfig">
                                <div id="controleDiarioBloquearOpt">
                                    <label>Bloquear diario?</label>
                                    <select value={bloqueadoValue} onChange={onChangeBloqueado}>
                                        <option value={1}>Sim</option>
                                        <option value={0}>Não</option>
                                    </select>
                                </div>

                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '1em', textAlign: 'center' }}>
                                    <label>Quantidade de dias</label>
                                    <input
                                        type="number"
                                        value={bloqueadoDias}
                                        onChange={onChangeBloqueadoDias}
                                        id="GerarDiarioButton" />
                                </div>
                            </div>

                            <input type="submit" value="Salvar" id="GerarDiarioButton" onClick={onSubmit} />
                            {/* criar uma linha divisoria */}
                            <hr style={{ width: '100%', maxHeight: '1px', color: '#313131', opacity: 0.3 }} />

                            <div id="controleDiarioStatusContainer">
                                <h4>Status de acesso atual</h4>
                                <div id="controleDiarioStatus">
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
                                        <label>
                                            <strong>Diário bloqueado?  &nbsp;</strong>
                                            {config.bloqueio_diario == 1 ? 'Sim' : 'Não'}
                                        </label>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
                                        <label>
                                            <strong>Prazo de lançamento:  &nbsp;</strong>
                                            {config.bloqueio_diario_dias} dias
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}