export default function CalcularBloqueioAnoIgual(diasBloqueio = 0) {
    const dataAtual = new Date();

    // Calcular a data da dias atrás do parametro informado
    const dataLimite = new Date();
    dataLimite.setDate(dataAtual.getDate() - diasBloqueio);

    // Formatar as datas para o formato yyyy-mm-dd
    const formatarData = (date) => date.toISOString().split('T')[0];
    const dataFormatada = formatarData(dataLimite)
    return dataFormatada
}