import CalcularBloqueioAnoDiferente from "./CalcularBloqueioAnoDiferente"
import CalcularBloqueioAnoIgual from "./CalcularBloqueioAnoIgual"

/**
 * Calcula a data de bloqueio diário com base em um parametro de numero de dias.
 * Se o parametro for 0, a data atual é retornada.
 * @param {Number} diasBloqueio - O numero de dias para calcular a data de bloqueio diário.
 * @returns {String | undefined} Uma string no formato yyyy-mm-dd.
 */
export default function CalcularDataBloqueioDiario(diasBloqueio = 0, anoPermitido = 0) {
    const anoAtual = new Date().getFullYear()

    if (anoPermitido === 0 || diasBloqueio === 0) {
        return undefined
    }

    if (anoPermitido !== anoAtual) {
        const resultado = CalcularBloqueioAnoDiferente(diasBloqueio, anoPermitido)
        return resultado
    }

    const resultado = CalcularBloqueioAnoIgual(diasBloqueio)
    return resultado
}