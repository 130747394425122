//RETURN BASICS INFO LIKE IF THE COMPANY IS ACTIVE AT THE CURRENTY TIME
import Axios from 'axios'

/**
 * Retrieves configuration information from the server.
 * @typedef {{
 *   id: number,
 *   Ano: number,
 *   Status: 'ativo' | 'inativo',
 *   Nota_Conceito: 0|1,
 *   ExcluirDados: 0|1,
 *   decimais_nota: 0|1,
 *   bloqueio_notas: 0|1,
 *   bloqueio_diario: 0|1,
 *   bloqueio_diario_dias: number
 * }} dataReturn
 * @param {string} baseUrl - The base URL for the API request.
 * @returns {Promise<dataReturn[]>} - A promise that resolves to the configuration data array.
 */
export default async function ConfigService(baseUrl) {
    let result = []

    if (baseUrl !== '') {
        await Axios.get(`${baseUrl}/info`)
            .then((response) => {
                result = response.data
            })
            .catch((response) => {
                result = response.data
            })

    }
    return result
}