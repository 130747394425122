const props = {
    id: 0,
    Ano: 2024,
    Status: 'ativo',
    Nota_Conceito: 0,
    ExcluirDados: 0,
    decimais_nota: 0,
    bloqueio_notas: 0,
    bloqueio_diario: 0,
    bloqueio_diario_dias: 0
}

const propsReturn = {
    bloqueioDiario: 0,
    bloqueioDias: 0
}

/**
 * @param {props} configInfo
 * @returns {propsReturn}
 */
export default function ValidarBloqueioDiario(configInfo = props) {
    
    if (configInfo.bloqueio_diario === undefined || configInfo.bloqueio_diario === 0) {
        return {
            bloqueioDiario: 0,
            bloqueioDias: 0
        }
    }

    return {
        bloqueioDiario: configInfo.bloqueio_diario,
        bloqueioDias: configInfo.bloqueio_diario_dias
    }
}