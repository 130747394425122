import Axios from 'axios'

/**
 * Atualiza o status do controle de di rio para a escola associada `sub`.
 * 
 * @param {string} [baseUrl] O caminho base para a API.
 * @param {0|1} [bloqueado] - Se o diario esta  bloqueado ou nao.
 * @param {0|1} [diasBloqueio] - Se o diario esta  bloqueado, quantos dias antes da data atual estar bloqueado.
 * @returns {Promise<{msg: 'Updated' | 'Error', status: number}>} - A resposta da API.
 */
export default async function PostControleDiarioUpdate(BaseUrl = '', bloqueado = 0, diasBloqueio = 0) {
    const resultado = await Axios.post(`${BaseUrl}/controleDiarioUpdate`, {
        bloqueado: bloqueado,
        diasBloqueio: diasBloqueio
    })
    return resultado.data
}