import './Extras.css'
import Iso from '@mui/icons-material/Iso';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import PendingActions from '@mui/icons-material/PendingActions';

/**
 * Retorna um array de objetos com as informa es dos itens que s o
 * renderizados na p gina de Extras do painel do administrador.
 * Cada objeto tem as seguintes propriedades:
 * 
 * @typedef {{ id: number, title: string, icon: JSX.Element, link: string, active: string }} Item
 *
 * - id: um identificador  nico para cada item
 * - title: o t tulo do item
 * - icon: o  cono do item
 * - link: o link para o qual o item ir  redirecionar
 * - active: se o item est  ativo ou n o
 *
 * @returns {Item[]} array de objetos com informa es dos itens
 */
export default function ExtrasItens() {
    const itens = [
        {
            id: 0,
            title: "ARREDONDAR NOTAS",
            icon: Iso,
            link: "/Admin/Extras/LiberarBloquearNotas",
            active: "Active"
        },
        {
            id: 1,
            title: "LIBERAR/BLOQUEAR NOTAS",
            icon: LockOpenIcon,
            link: "/Admin/Extras/LiberarBloquearNotas",
            active: "Active"
        },
        {
            id: 2,
            title: "TRANSFERIR FALTAS",
            icon: MoveDownIcon,
            link: "/Admin/Extras/TransferirFaltas",
            active: "Active"
        },
        {
            id: 3,
            title: "CONTROLE DO DIARIO",
            icon: PendingActions,
            link: "/Admin/Extras/ControleDiario",
            active: "Active"
        }
    ]

    return itens
}
