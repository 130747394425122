import React, { useEffect, useState } from 'react'
import Visibility from '@material-ui/icons/Visibility'
import SearchIconAnimated from '../../images/Search-1s-200px.svg'
import formatarData from '../../Functions/FormataData'
import './DiarioTabela.css'

export default function DiarioTabela({ bodyDataRows, headerTableTitles, bodyTableFields, filterBy, searchStatusIcon, sessionValues }) {

    const [searchStatus, setSearchStatus] = useState(searchStatusIcon)
    const [pessoasFiltro, setPessoasFiltro] = useState(bodyDataRows)
    const tableTitles = headerTableTitles
    const tableFields = bodyTableFields
    const filterByProp = filterBy

    useEffect(() => {
        if (bodyDataRows.length > 0) {
            setSearchStatus(false)
        }
        setPessoasFiltro(bodyDataRows)
    }, [bodyDataRows])

    useEffect(() => {
        setSearchStatus(searchStatusIcon)
    }, [searchStatusIcon])

    const onClickPessoa = async (dado) => {
        sessionStorage.setItem('d', JSON.stringify(dado))
        sessionStorage.setItem('selectedData', JSON.stringify(sessionValues))
        window.location.replace('/Admin/Diario/Dados')
    }

    return (
        <div className='DiarioTabelaDashContainer'>

            <div className='DiarioTabelaDashTable'>
                {
                    searchStatus === false ?
                        <table >
                            <thead>
                                <tr>
                                    {tableTitles?.map(item => (
                                        <th>{item}</th>
                                    )
                                    )}
                                    <th>
                                        <Visibility id="IconViewDiario" style={{ display: 'none' }} />
                                    </th>
                                </tr>
                            </thead>

                            <tbody >
                                {pessoasFiltro?.map((val, key) => {
                                    return (
                                        <tr id={"Row" + key} name={val[filterByProp]} >
                                            {tableFields?.map((item, key) => (
                                                <td name={item} >
                                                    {
                                                        new Date(val[item]?.toString()) instanceof Date && !isNaN(new Date(val[item]?.toString())) && item !== 'aulas' ? //Valida se é data
                                                            //new Date(val[item]?.toString()).toLocaleString().split(',')[0] : //Formata para data local caso verdadeiro
                                                            formatarData(val[item]) : //Formata para data local caso verdadeiro
                                                            val[item]?.toString()?.toUpperCase() //Texto em caixa alta caso não seja data
                                                    }
                                                </td>
                                            )
                                            )}
                                            <Visibility id="IconViewDiario" onClick={() => onClickPessoa(val)} />
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table> :
                        <div id='ContainerIconSearchLoadingStatus'>
                            <p>
                                Procurando...
                            </p>
                            <img src={SearchIconAnimated} alt='Search Icon' />
                        </div>
                }
            </div>
        </div>
    )
}